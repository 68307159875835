import Cookies from 'js-cookie'

// token参数
const TokenKey = 'Admin-Token'
const TokenName = 'Name-Token'
const User = 'User'
const CompanyName = 'company-name'
const CompanyId = 'company-id'
const UserType = 'user-type'
setUserType
/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
  return Cookies.get(TokenKey)
}

/**
 * 设置token
 * @param token
 * @returns {*}
 */
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

/**
 * 删除token
 * @returns {*}
 */
export function removeToken() {
  return Cookies.remove(TokenKey)
}

/**
 * 获取token名称
 * @returns {*}
 */
export function getTokenName() {
  return Cookies.get(TokenName)
}

/**
 * 设置token名称
 * @param token
 * @returns {*}
 */
export function setTokenName(token) {
  return Cookies.set(TokenName, token)
}

/**
 * 获取用户
 * @returns {*}
 */
export function getUser() {
  return Cookies.get(User)
}

/**
 * 设置用户
 * @param user
 * @returns {*}
 */
export function setUser(token) {
  return Cookies.set(User, token)
}

/**
 * 设置用企业
 * @param user
 * @returns {*}
 */
export function setCompanyName(name) {
  return Cookies.set(CompanyName, name)
}
/**
 * 设置用企业id
 * @param user
 * @returns {*}
 */
export function setCompanyId(id) {
  return Cookies.set(CompanyId, id)
}

/**
 * 获取企业
 * @param user
 * @returns {*}
 */
export function getCompanyName() {
  return Cookies.get(CompanyName)
}
/**
 * 获取企业id
 * @param user
 * @returns {*}
 */
export function getCompanyId() {
  return Cookies.get(CompanyId)
}

/**
 * 设置用户类型
 * @param UserType
 * @returns {*}
 */
export function setUserType(id) {
  return Cookies.set(UserType, id)
}

/**
 * 获取用户类型
 * @param UserType
 * @returns {*}
 */
export function getUserType() {
  return Cookies.get(UserType)
}

/**
 * 获取menutree 加载标识
 * @param
 * @returns {*}
 */
export function getMenuFlag() {
  return Cookies.get('menuflag')
}
/**
 * 设置menutree 加载标识
 * @param val
 * @returns {*}
 */
export function setMenuFlag(val) {
  return Cookies.set('menuflag', val)
}

/**
 * 获取登录名称
 * @returns {*}
 */
export function getUserName() {
  return Cookies.get('userName')
}

/**
 * 设置登录名称
 * @param name
 * @returns {*}
 */
export function setUserName(name) {
  return Cookies.set('userName', name)
}
