<template>
  <div>
    <el-dialog
      :title="addOrEdit == 'add' ? '新增摄像头' : '编辑摄像头'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="640px"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form
        v-if="dialogVisible"
        ref="form"
        :rules="rule"
        :model="form"
        label-width="100px"
        :inline="true"
        label-position="right"
      >
        <el-form-item label="设备编号:">
          <el-input
            v-model="form.cameraCode"
            placeholder="请输入设备编号"
            maxlength="40"
            :style="labelWidthMax"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称:" prop="cameraName">
          <el-input
            v-model="form.cameraName"
            placeholder="请输入设备名称"
            :style="labelWidthMax"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备类型:">
          <el-select
            v-model="type"
            popper-class="gis-default"
            disabled
            placeholder="请选择设备类型"
            :style="labelWidth"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备条码:" prop="barCode">
          <el-input
            v-model="form.barCode"
            placeholder="请输入设备条码"
            :style="labelWidth"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类一:">
          <el-select
            v-model="form.cameraKind"
            popper-class="gis-default"
            placeholder="请选择分类一"
            :style="labelWidth"
          >
            <el-option
              v-for="item in cameraKindDict"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规格:">
          <el-select
            v-model="form.specCode"
            popper-class="gis-default"
            placeholder="请选择规格"
            :style="labelWidth"
          >
            <el-option
              v-for="item in specDict"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂商:">
          <el-select
            v-model="form.factoryCode"
            popper-class="gis-default"
            placeholder="请选择厂商"
            :style="labelWidth"
            @change="factoryCodeChge"
          >
            <el-option
              v-for="item in factoryDict"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号:">
          <el-select
            v-model="form.modelCode"
            popper-class="gis-default"
            placeholder="请选择型号"
            :style="labelWidth"
          >
            <el-option
              v-for="item in modelDict"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="权属单位:" prop="companyId">
          <el-select
            v-model="form.companyId"
            popper-class="gis-default"
            disabled
            placeholder="请选择权属单位"
            :style="labelWidth"
          >
            <el-option
              v-for="item in companyIdDict"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否在线:" prop="online">
          <el-select
            v-model="form.online"
            popper-class="gis-default"
            placeholder="请选择是否在线"
            :style="labelWidth"
          >
            <el-option
              v-for="item in optionsAideo"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装日期:" prop="installDate">
          <el-date-picker
            v-model="form.installDate"
            type="date"
            :clearable="false"
            :editable="false"
            popper-class="datePickerClass"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :style="labelWidth"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="设备状态:" prop="generalStatus">
          <el-select
            v-model="form.generalStatus"
            popper-class="gis-default"
            placeholder="请选择设备状态"
            :style="labelWidth"
          >
            <el-option
              v-for="item in optionsStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否远传:" prop="transmit">
          <el-select
            v-model="form.transmit"
            popper-class="gis-default"
            placeholder="请选择是否远传"
            :style="labelWidth"
          >
            <el-option
              v-for="item in optionsAideo"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用协议:">
          <el-input
            v-model="form.useAccord"
            placeholder="请输入使用协议"
            maxlength="100"
            :style="labelWidthMax"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器地址:">
          <el-input
            v-model="form.serveUrl"
            placeholder="请输入服务器地址"
            maxlength="100"
            :style="labelWidthMax"
          ></el-input>
        </el-form-item>

        <el-form-item label="经纬度:">
          <el-input
            v-model="form.point"
            disabled
            placeholder="请输入经纬度"
            :style="labelWidthMax"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button v-if="addOrEdit === 'add'" size="mini" type="primary" @click="submit"
          >保存</el-button
        >
        <el-button v-else size="mini" type="primary" @click="updateData">保存</el-button>
        <el-button size="mini" @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveCamera, cameraUpdate } from '../apis/equipment'
import {
  findDictTree,
  findCmpOptions,
  findManufacturerOptions,
  findTypeOptions,
  findSpecOptions
} from '../apis/commonType'
import { commonDict } from '../utils/dict.js'
import { getCompanyName, getCompanyId } from '../utils/auth'
export default {
  data() {
    return {
      eqType: 32,
      addOrEdit: 'add',
      type: 'camera',
      form: {
        transmit: '',
        generalStatus: '',
        installDate: '',
        online: ''
      },
      title: '',
      dialogVisible: false,
      typeList: [],
      labelWidth: 'width:180px',
      labelWidthMax: 'width:470px',
      options: [
        {
          value: 'warn',
          label: '泄露报警器'
        },
        {
          value: 'camera',
          label: '摄像头'
        }
      ],
      optionsAideo: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      optionsStatus: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '停用'
        }
      ],
      cameraKindDict: [],
      companyIdDict: [],
      factoryDict: [],
      modelDict: [],
      specDict: [],
      rule: {
        cameraName: [
          { required: true, message: '请输入设备名称', trigger: 'change' },
          { max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }
        ],
        barCode: [
          { required: true, message: '请输入设备条码', trigger: 'change' },
          { max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }
        ],
        companyId: [{ required: true, message: '请选择权属单位', trigger: 'change' }],
        warningType: [{ required: true, message: '请选择报警器类型', trigger: 'change' }],
        generalStatus: [{ required: true, message: '请选择设备状态', trigger: 'change' }],
        installDate: [{ required: true, message: '请选择安装日期', trigger: 'change' }],
        transmit: [{ required: true, message: '请选择是否远传', trigger: 'change' }],
        online: [{ required: true, message: '请选择是否在线', trigger: 'change' }]
      }
    }
  },
  async mounted() {
    this.form.companyName = getCompanyName()
    this.form.companyId = Number(getCompanyId())
    this.form.installDate = this.getDate()
    this.getCodeName()
  },
  methods: {
    // 获取型号
    getFindTypeOptions(manufacturerCode) {
      findTypeOptions({ eqType: this.eqType, manufacturerCode }).then(res => {
        if (res.data) {
          this.modelDict = res.data
        }
      })
    },
    // 获取规格
    getFindSpecOptions(manufacturerCode) {
      findSpecOptions({ eqType: this.eqType, manufacturerCode }).then(res => {
        if (res.data) {
          this.specDict = res.data
        }
      })
    },
    factoryCodeChge(value) {
      this.$set(this.form, 'modelCode', null)
      this.$set(this.form, 'specCode', null)
      if (value) {
        this.getFindTypeOptions(value)
        this.getFindSpecOptions(value)
      }
    },
    openDialog(data) {
      this.addOrEdit = data.addOrEdit
      if (data.addOrEdit === 'add') {
        this.form.point = data.lnglat
      } else {
        this.form = data
        if (this.form.factoryCode) {
          this.getFindTypeOptions(this.form.factoryCode)
          this.getFindSpecOptions(this.form.factoryCode)
        }
      }
      this.dialogVisible = true
    },
    getCodeName() {
      this.form.online = this.optionsAideo[1].value
      this.form.generalStatus = this.optionsStatus[0].value
      this.form.transmit = this.optionsAideo[0].value
      let code = commonDict[100014].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.cameraKindDict = res.data[commonDict[100014].code]
        }
      })
      /* findCmpOptions({ types: 2 }).then(res => {
        if (res.data) {
          this.companyIdDict = res.data
        }
      }) */
      findManufacturerOptions({ eqType: this.eqType, companyId: getCompanyId() }).then(res => {
        if (res.data) {
          this.factoryDict = res.data
        }
      })
    },
    getDate() {
      var d = new Date()
      var year = d.getFullYear()
      var month = d.getMonth() + 1
      var date = d.getDate()
      var curDateTime = year + '-'
      if (month > 9) curDateTime = curDateTime + '' + month + '-'
      else curDateTime = curDateTime + '0' + month + '-'
      if (date > 9) curDateTime = curDateTime + '' + date
      else curDateTime = curDateTime + '0' + date
      return curDateTime
    },
    close() {
      this.form = {}
      this.dialogVisible = false
      this.$parent.$parent.equipmentTool.close(true)
      this.$parent.$parent.pointHighlightRemove()
      if (this.addOrEdit == 'add') {
        this.$parent.startDrawEquipment({ value: this.type })
        this.form.online = this.optionsAideo[0].value
        this.form.generalStatus = this.optionsStatus[0].value
        this.form.transmit = this.optionsAideo[0].value
      }
    },
    submit() {
      let that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          that.form = {
            ...that.form,
            layerId: this.$store.getters.currentLayer
          }
          saveCamera(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                this.$eventBus.$emit('setFilter')
              } else {
                that.$message.error(res.msg)
              }
              that.close()
            })
            
        }
      })
    },
    updateData() {
      let that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          cameraUpdate(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
              } else {
                that.$message.error(res.msg)
              }
              that.close()
            })
            
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: center;

  button {
    margin: 7px 15px 0 15px;
  }
}
</style>
