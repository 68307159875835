import request from '../utils/request'

//总览字典
export function pipeOption(params) {
  return request({
    url: '/statistics/getStatisticsPipeOption',
    method: 'get',
    params
  })
}

//工具箱-标记新增或更新
export function saveMake(params) {
  return request({
    url: '/sketch/save',
    method: 'POST',
    data: params
  })
}
export function updateMake(params) {
  return request({
    url: '/sketch/update',
    method: 'POST',
    data: params
  })
}

export function detectionPointSave(params){
  return request({
    url:'/gis/basic/sign/detectionPoint/save',
    method: 'POST',
    data: params
  })
}

export function updateDetailById(params){
  return request({
    url:'/gis/basic/sign/detectionPoint/updateDetailById',
    method: 'POST',
    data: params
  })
}
export function workHiddenSave(params){
  return request({
    url:'/gis/basic/sign/workHidden/save',
    method: 'POST',
    data: params
  })
}
export function workHiddenUpdateDetailById(params){
  return request({
    url:'/gis/basic/sign/workHidden/updateDetailById',
    method: 'POST',
    data: params
  })
}

//工具箱-标记查询
export function makeList(params) {
  return request({
    url: '/sketch/list',
    method: 'get',
    params
  })
}
//工具箱-删除
export function delMake(params) {
  return request({
    url: '/sketch/delete',
    method: 'get',
    params
  })
}
//上传文件
// export function uploadImg(data) {
//   return request({
//     url: '/gasOss/upload',
//     method: 'post',
//     data,
//     ContentType: 'multipart/form-data'
//   })
// }

//删除上传文件
// export function uploadImgDelete(params) {
//   return request({
//     url: '/gasOss/delete',
//     method: 'get',
//     params
//   })
// }
//上传文件住建局
export function uploadImg(data) {
  return request({
    url: '/aliYun/uploadGasPoliceImgToAliYunOSS',
    method: 'post',
    data,
    ContentType: 'multipart/form-data'
  })
}
//删除上传文件
export function uploadImgDelete(params) {
  return request({
    url: '/aliYun/deleteImgFromAliYunOSS',
    method: 'get',
    params
  })
}
//工具栏获取详情
export function findById(params) {
  return request({
    url: '/sketch/findById',
    method: 'get',
    params
  })
}
//获取数据字典
export function findDictTree(params) {
  return request({
   // url: '/gasDict/findDictTree',
   url: '/dict/findDictTree',
    method: 'get',
    params
  })
}
//获取字典新的
export function selectDictListByParentCode(params) {
  return request({
    url: '/dict/selectDictListByParentCode',
    method: 'get',
    params
  })
}
//获取字典通过pid新的
export function selectDictListByPid(params) {
  return request({
    url: '/dict/selectDictListByPid',
    method: 'get',
    params
  })
}

//获取数据公司
export function findCmpOptions(params) {
  return request({
    url: '/options/enterprise',
    method: 'get',
    params
  })
}
//批量修改线
export function batchUpdate(params) {
  return request({
    url: '/pipe/batchUpdate',
    method: 'post',
    data: params
  })
}
export function findPressureLevelOptions(params) {
  return request({
    url: '/pipe/findPressureLevelOptions',
    method: 'get',
    params
  })
}
// 厂商
export function findManufacturerOptions(params) {
  return request({
    url: '/options/findManufacturerOptions',
    method: 'get',
    params
  })
}
// 规格
export function findSpecOptions(params) {
  return request({
    url: '/options/findSpecOptions',
    method: 'get',
    params
  })
}
// 型号
export function findTypeOptions(params) {
  return request({
    url: '/options/findTypeOptions',
    method: 'get',
    params
  })
}
//爆管分析

export function getAnalysisPipeBurst(params) {
  return request({
    url: '/gis/one/map/getAnalysisPipeBurst',
    method: 'get',
    params
  })
}
export function getAnalysisCloseValve(params) {
  return request({
    url: '/gis/one/map/getAnalysisCloseValve',
    method: 'get',
    params
  })
}
export function getWorkFacilityAcceptDetail(params) {
  return request({
    url: '/gis/one/map/getWorkFacilityAcceptDetail',
    method: 'get',
    params
  })
}
export function getPatrolEventsDetail(params) {
  return request({
    url: '/gis/one/map/getPatrolEventsDetail',
    method: 'get',
    params
  })
}
// 导入管线excel
export function pipeImportExcel(data) {
  return request({
    url: '/pipe/importExcel',
    method: 'post',
    data,
    ContentType: 'multipart/form-data'
  })
}
// 导入管线txt
export function pipeImportTxt(data) {
  return request({
    url: '/pipe/importTxt',
    method: 'post',
    data,
    ContentType: 'multipart/form-data'
  })
}
// 导入管线kml
export function pipeImportKml(data) {
  return request({
    url: '/pipe/importKml',
    method: 'post',
    data,
    ContentType: 'multipart/form-data'
  })
}
//聚合场站
export function selectCountStationPointGroup(data) {
  return request({
    url: '/station/selectCountStationPointGroup',
    method: 'post',
    data,
  })
}
//聚合阀门
export function selectCountValvePointGroup(data) {
  return request({
    url: '/valve/selectCountValvePointGroup',
    method: 'post',
    data,
  })
}