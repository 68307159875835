import request from '../utils/request'

// 泄漏报警新增
export function saveWarn(params) {
  return request({
    url: '/warning/save',
    method: 'POST',
    data: params
  })
}

// 修改泄漏报警
export function update(params) {
  return request({
    url: '/warning/update',
    method: 'POST',
    data: params
  })
}
// 查询泄漏报警
export function getFindById(params) {
  return request({
    url: '/warning/findById',
    method: 'get',
    params
  })
}
// 删除泄漏报警
export function warningDelete(params) {
  return request({
    url: '/warning/delete',
    method: 'get',
    params
  })
}
// 新增摄像头
export function saveCamera(params) {
  return request({
    url: '/camera/save',
    method: 'POST',
    data: params
  })
}
// 查询摄像头
export function getCameraFindById(params) {
  return request({
    url: '/camera/findById',
    method: 'get',
    params
  })
}
// 删除摄像头
export function cameraDelete(params) {
  return request({
    url: '/camera/delete',
    method: 'get',
    params
  })
}
// 修改泄漏报警
export function cameraUpdate(params) {
  return request({
    url: '/camera/update',
    method: 'POST',
    data: params
  })
}
