import axios from 'axios'
import domMessage from './MessageOnce'
import { Loading } from 'element-ui'
let Message = new domMessage()
import router from '@/router/index'
import { getToken, getTokenName } from './auth'
import global from '@/global';

let loading
const whiteList = ['/pipe/link','/station/selectCountStationPointGroup','/valve/selectCountValvePointGroup','/layer/findTree','/config/pipe/show/queryConfig','/bzMessageConfig/getUserMessageCount','/dict/findDictTree'] // no redirect whitelist
//const whiteList = ['/pipe/link','/station/selectCountStationPointGroup','/valve/selectCountValvePointGroup'] // no redirect whitelist
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)',
    fullscreen: true
  })
}
function endLoading() {
  loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
//http request 拦截器
const service = axios.create({
   baseURL: global.baseUrl, // api 的 base_url//此处注释，在main中的ajaxBaseUrl存在异步，在下面赋值
  timeout: 300000 // request timeout
})

service.defaults.withCredentials = true
service.interceptors.request.use(
  config => {
    if (whiteList.indexOf(config.url) == -1) {
      showFullScreenLoading()
    }

    config.url = config.url //ajaxBaseUrl 在此处赋值。
    
    let token = localStorage.getItem('tokenValue')
    let name = localStorage.getItem('tokenName')
    if (name) {
      config.headers[name] = token
    }
    // config.headers = {
    //   'rqjt-sso-token': '27329ba6-3d38-499b-af43-ef3c41aa7b71'
    // }
    if (!config.headers['Content-Type']) {
      if (config.method == 'post') {
        config.headers['Content-Type'] = 'application/json'
      } else if (config.method == 'get') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }
    }
    if (config.ContentType) config.headers['Content-Type'] = config.ContentType
    // } else if (config.method == 'get') {
    //   let url = config.url.split('/')
    //   if(url[2] == 'ExportFile') {
    // 		config.headers['Content-Type'] = 'multipart/form-data'
    // 	}else{
    //     config.headers = {
    //       'gas-rqjt-token': '7a89206c-454d-40b1-a7f1-df5d9b33f055'
    //     }
    //   }
    // }

    return config
  },
  error => {
    return Promise.reject(err)
  }
)
// 导出文件流的接口
const excelData = [
  // '/apis//warehouse/exportWarehouseExcel',
  // '/apis//transportInfo/exportExcel',
  // '/apis//emergencyTravelInfo/exportExcel',
  // '/apis//worker/exportExcel',
  // '/apis//dataWarningmessage/exportWarningmessageExcel',
  // '/apis//gasStationFillingInformation/exportExcel'
]
service.interceptors.response.use(
  response => {
    tryHideFullScreenLoading()
    if (response.data.code == -104) {
      Message.error(response.data && response.data.msg)
      router.push('/Login').catch(err => {})
      return Promise.reject()
    } else if (
      (response.data && response.data.code == 200) ||
      response.config.responseType == 'arraybuffer'
      // excelData.includes(response.config.url)
    ) {
      return response.data
    }
    // else if (response.data && response.data.code) {
    //   Message.error(response.data.msg)
    //   return Promise.reject()
    // }
    else if (response.data && response.data.msg) {
    //  if( response.data.code!=500){
        Message.error(response.data.msg)
    //  }
       
      return Promise.reject(Error(response.data.msg))
    } else {
      Promise.reject(Error(`访问后端接口 [ ${response.config.url} ] 失败`))
    }
  },
  error => {
    tryHideFullScreenLoading()
    return Promise.reject(Error(error))
  }
)

export default service
